<template>
  <div class="container">
    <!-- 讲师审核 -->
    <header-bar></header-bar>
    <div class="yh_filter bg_white">
      <el-form class="form" :inline="true">
        <el-row>
          <el-col :span="6">
            <el-form-item label="讲师姓名">
              <el-input
                clearable
                v-model="formData.teaName"
                placeholder="请输入讲师姓名"
                maxlength="10"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select
                clearable
                v-model="formData.state"
                placeholder="请选择"
              >
                <el-option value="" label="全部"></el-option>
                <el-option
                  v-for="item in lecturerState"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button
              icon="el-icon-search"
              class="btn btn_blue"
              @click="search"
            >搜索
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table">
      <el-table :data="tableData" tooltip-effect="dark" style="width: 100%">
        <el-table-column
          label="提交时间"
          prop="submitTime"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="讲师姓名"
          prop="teaName">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span
              :class="{
                color_orange: scope.row.state == 2,
                color_green: scope.row.state == 1,
                color_red: scope.row.state == 3,
                color_gray: scope.row.state == 9,
              }"
            >{{ scope.row.stateName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="创建人"
          prop="creatorName"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{
                scope.row.creatorName ? scope.row.creatorName : "--"
              }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="审核时间"
          prop="publishTime"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <span class="operate color_blue" @click="detail(scope.row)"
            >预览</span
            >
            <span class="operate color_blue" @click="getAuditLogList(scope.row)"
            >审核记录</span
            >
            <span
              v-if="scope.row.state == 2"
              class="operate color_orange"
              @click="review(scope.row)"
            >审核</span
            >
            <span
              v-if="scope.row.state == 1 || scope.row.state == 2"
              class="operate color_red"
              @click="updateCourse(scope.row)"
            >下架</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      :pageSize="formData.pageSize"
      :total="totalCount"
      @pageChange="pageChange"
      ref="pageRef"
    />
    <el-dialog
      class="dialog_wrapper"
      v-if="isReviewDialogVisible"
      :visible.sync="isReviewDialogVisible"
      :close-on-click-modal="false"
      width="480"
    >
      <check :courseId="courseId" @changeDialogClose="closeDialog"></check>
    </el-dialog>
    <el-drawer
      :visible.sync="isDrawerVisible"
      :with-header="false"
      :wrapperClosable="true"
    >
      <div class="gl_drawer">
        <div class="gl_drawer_title">
          <span
            class="gl_state"
            :class="{
              color_orange: currentState == 2,
              color_green: currentState == 1,
              color_red: currentState == 3,
              color_gray: currentState == 9,
            }"
          >{{ currentStateName }}</span
          >
          <h2 :title="lecturerTitle">{{ lecturerTitle }}</h2>
        </div>
        <div class="gl_drawer_cont">
          <p class="gl_drawer_t">审批历史</p>
          <ul v-if="auditCount > 0">
            <li v-for="item in auditList" :key="item.logId">
              <span class="gl_audit_user">{{ item.operatorName }}</span
              >{{
                item.operation
              }}<span class="gl_audit_time">{{ item.operateTime }}</span>
              <p v-if="item.remark" class="gl_audit_info">
                "{{ item.remark }}"
              </p>
            </li>
          </ul>
          <p v-else class="gl_empty">——— 暂无审批数据 ———</p>
        </div>
        <pagination
          class="hh_page"
          v-if="auditCount > recordData.pageSize"
          :pageSize="recordData.pageSize"
          :total="auditCount"
          @pageChange="auditPageChange"
          ref="child"
        />
      </div>
    </el-drawer>
    <el-dialog
      class="dialog_wrapper"
      title="讲师详情"
      v-if="isDetail"
      :visible.sync="isDetail"
      :modal-append-to-body="false"
      top="15vh"
      width="650px"
    >
      <lecturer-detail
        :id="teaId"
        :type="auditType"
        @closeDialog="getCloseDialog"
      ></lecturer-detail>
    </el-dialog>
  </div>
</template>

<script>
import headerBar from "@comps/backStageComponent/layout/headerBar/headerBar";
import lecturerDetail from "@views/backStageEnd/lecturer/components/lecturerDetail";
import Pagination from "@comps/backStageComponent/pagination";
import check from "./component/checkDetail";
import API from "@apis/backStageEndAPI/lecturerAPI";
import CommonUtils from "@utils/index.js";
export default {
  name: "lecturerCheck",
  components: {
    headerBar,
    Pagination,
    check,
    lecturerDetail
  },
  data() {
    return {
      totalCount: 0,
      tableData: [],
      formData: {
        page: 1, //页码
        pageSize: 15, //	分页大小
        teaName: "", //讲师名称
        state: "", //状态
      },
      lecturerState: [
        {id: 1, label: "已发布"},
        {id: 2, label: "待审核"},
        {id: 3, label: "已驳回"},
        {id: 9, label: "已下架"},
      ],
      stateMap: "",
      courseId: "",
      isReviewDialogVisible: false,
      lecturerTitle: "讲师姓名",
      isDrawerVisible: false,
      isDetail: false,
      recordData: {
        page: 1,
        pageSize: 10,
        itemId: '',
        itemType: 2
      },
      auditCount: 0,
      currentRow: "",
      auditList: [],
      currentState: "",
      currentStateName: "",
      teaId: '',
      auditType: false
    };
  },
  mounted() {
    this.initState();
    this.getAuditList();
  },
  methods: {
    initState() {
      this.stateMap = new Map();
      this.stateMap.set(0, "未发布");
      this.stateMap.set(1, "已发布");
      this.stateMap.set(2, "待审核");
      this.stateMap.set(3, "已驳回");
      this.stateMap.set(9, "已下架");
    },
    search() {
      this.formData.page = 1;
      this.$refs.pageRef.page = 1
      this.getAuditList();
    },
    getAuditList() {
      API.getAuditList(CommonUtils.parseToParams(this.formData)).then(
        (res) => {
          this.tableData = res?.dataList;
          this.tableData.length &&
          this.tableData.forEach((item) => {
            this.$set(item, "stateName", this.stateMap.get(item.state));
            // item.stateName = this.stateMap.get(item.state)
          });
          this.totalCount = res.rowCount;
        }
      );
    },
    medicalChange(val) {
      if (val) {
        this.formData.subjectId = val[val.length - 1];
      } else {
        this.formData.subjectId = "";
      }
    },
    detail(item) {
      this.teaId = item.teaId
      this.auditType = false
      this.isDetail = true
    },
    async getAuditLogList(item) {//获取审核记录
      this.currentRow = item;
      this.currentState = item.state;
      this.currentStateName = this.stateMap.get(item.state);
      this.lecturerTitle = item.teaName;
      this.recordData.itemId = item.teaId
      let data = await API.getAuditLogList(
        CommonUtils.parseToParams(this.recordData)
      );
      this.auditList = data.dataList;
      this.auditCount = data.rowCount;
      this.isDrawerVisible = true;
    },
    auditPageChange(val) {
      //审核记录页面跳转
      this.recordData.page = val;
      this.getAuditLogList(this.currentRow);
    },
    pageChange(val) {
      //表格页面跳转
      this.formData.page = val;
      this.getAuditList();
    },
    review(item) { //审核
      this.teaId = item.teaId
      this.auditType = true
      this.isDetail = true
    },
    getCloseDialog() {//关闭审核弹框
      this.isDetail = false
      this.getAuditList()
    },
    closeDialog() {
      this.isReviewDialogVisible = false;
      this.courseId = "";
      this.getAuditList();
    },
    updateCourse(item) {
      this.$confirm("确定要下架该讲师吗？", {})
        .then((res) => {
          let params = {
            teaId: item.teaId,
            state: 9,
          };
          API.getStopStart(params).then((res) => {
            this.getAuditList();
          });
        })
        .catch(() => {
        });
    },
  },
};
</script>

<style scoped lang="scss">
.color_gray {
  color: #dcdcdc;
}
.yh_filter {
  margin-bottom: 16px;
  padding: 16px 16px 0;
  border-radius: 12px;
  .btn {
    margin-left: 20px;
  }
}
.gl_drawer {
  width: 100%;
  box-sizing: border-box;
  .gl_drawer_title {
    position: relative;
    padding: 0 40px 0 70px;
    background: var(--colorBlue);
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    h2 {
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .gl_state {
      position: absolute;
      top: 50%;
      left: 8px;
      padding: 4px 8px;
      border-radius: 16px;
      background: #fff;
      font-size: 12px;
      line-height: 100%;
      transform: translateY(-50%);
    }
  }
  .gl_empty {
    color: #666;
    text-align: center;
  }
  li {
    margin-bottom: 16px;
    padding: 8px;
    background: #f7faff;
    .gl_audit_user {
      padding-right: 6px;
      color: var(--colorBlue);
    }
    .gl_empty {
      color: #666;
      text-align: center;
    }
    li {
      margin-bottom: 16px;
      padding: 8px;
      background: #f7faff;
      .gl_audit_user {
        padding-right: 6px;
        color: var(--colorBlue);
      }
      .gl_audit_time {
        float: right;
        color: #666;
      }
      .gl_audit_info {
        padding: 4px;
      }
    }
  }
}
.hh_page {
  min-width: 115%;
}
::v-deep .el-pagination__jump {
  margin-left: 0;
}
</style>
