var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('header-bar'),_c('div',{staticClass:"yh_filter bg_white"},[_c('el-form',{staticClass:"form",attrs:{"inline":true}},[_c('el-row',[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"讲师姓名"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入讲师姓名","maxlength":"10","show-word-limit":""},model:{value:(_vm.formData.teaName),callback:function ($$v) {_vm.$set(_vm.formData, "teaName", $$v)},expression:"formData.teaName"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"状态"}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择"},model:{value:(_vm.formData.state),callback:function ($$v) {_vm.$set(_vm.formData, "state", $$v)},expression:"formData.state"}},[_c('el-option',{attrs:{"value":"","label":"全部"}}),_vm._l((_vm.lecturerState),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.label,"value":item.id}})})],2)],1)],1),_c('el-col',{attrs:{"span":4}},[_c('el-button',{staticClass:"btn btn_blue",attrs:{"icon":"el-icon-search"},on:{"click":_vm.search}},[_vm._v("搜索 ")])],1)],1)],1)],1),_c('div',{staticClass:"table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"tooltip-effect":"dark"}},[_c('el-table-column',{attrs:{"label":"提交时间","prop":"submitTime","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"讲师姓名","prop":"teaName"}}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:{
              color_orange: scope.row.state == 2,
              color_green: scope.row.state == 1,
              color_red: scope.row.state == 3,
              color_gray: scope.row.state == 9,
            }},[_vm._v(_vm._s(scope.row.stateName))])]}}])}),_c('el-table-column',{attrs:{"label":"创建人","prop":"creatorName","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.creatorName ? scope.row.creatorName : "--"))])]}}])}),_c('el-table-column',{attrs:{"label":"审核时间","prop":"publishTime","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"操作","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"operate color_blue",on:{"click":function($event){return _vm.detail(scope.row)}}},[_vm._v("预览")]),_c('span',{staticClass:"operate color_blue",on:{"click":function($event){return _vm.getAuditLogList(scope.row)}}},[_vm._v("审核记录")]),(scope.row.state == 2)?_c('span',{staticClass:"operate color_orange",on:{"click":function($event){return _vm.review(scope.row)}}},[_vm._v("审核")]):_vm._e(),(scope.row.state == 1 || scope.row.state == 2)?_c('span',{staticClass:"operate color_red",on:{"click":function($event){return _vm.updateCourse(scope.row)}}},[_vm._v("下架")]):_vm._e()]}}])})],1)],1),_c('pagination',{ref:"pageRef",attrs:{"pageSize":_vm.formData.pageSize,"total":_vm.totalCount},on:{"pageChange":_vm.pageChange}}),(_vm.isReviewDialogVisible)?_c('el-dialog',{staticClass:"dialog_wrapper",attrs:{"visible":_vm.isReviewDialogVisible,"close-on-click-modal":false,"width":"480"},on:{"update:visible":function($event){_vm.isReviewDialogVisible=$event}}},[_c('check',{attrs:{"courseId":_vm.courseId},on:{"changeDialogClose":_vm.closeDialog}})],1):_vm._e(),_c('el-drawer',{attrs:{"visible":_vm.isDrawerVisible,"with-header":false,"wrapperClosable":true},on:{"update:visible":function($event){_vm.isDrawerVisible=$event}}},[_c('div',{staticClass:"gl_drawer"},[_c('div',{staticClass:"gl_drawer_title"},[_c('span',{staticClass:"gl_state",class:{
            color_orange: _vm.currentState == 2,
            color_green: _vm.currentState == 1,
            color_red: _vm.currentState == 3,
            color_gray: _vm.currentState == 9,
          }},[_vm._v(_vm._s(_vm.currentStateName))]),_c('h2',{attrs:{"title":_vm.lecturerTitle}},[_vm._v(_vm._s(_vm.lecturerTitle))])]),_c('div',{staticClass:"gl_drawer_cont"},[_c('p',{staticClass:"gl_drawer_t"},[_vm._v("审批历史")]),(_vm.auditCount > 0)?_c('ul',_vm._l((_vm.auditList),function(item){return _c('li',{key:item.logId},[_c('span',{staticClass:"gl_audit_user"},[_vm._v(_vm._s(item.operatorName))]),_vm._v(_vm._s(item.operation)),_c('span',{staticClass:"gl_audit_time"},[_vm._v(_vm._s(item.operateTime))]),(item.remark)?_c('p',{staticClass:"gl_audit_info"},[_vm._v(" \""+_vm._s(item.remark)+"\" ")]):_vm._e()])}),0):_c('p',{staticClass:"gl_empty"},[_vm._v("——— 暂无审批数据 ———")])]),(_vm.auditCount > _vm.recordData.pageSize)?_c('pagination',{ref:"child",staticClass:"hh_page",attrs:{"pageSize":_vm.recordData.pageSize,"total":_vm.auditCount},on:{"pageChange":_vm.auditPageChange}}):_vm._e()],1)]),(_vm.isDetail)?_c('el-dialog',{staticClass:"dialog_wrapper",attrs:{"title":"讲师详情","visible":_vm.isDetail,"modal-append-to-body":false,"top":"15vh","width":"650px"},on:{"update:visible":function($event){_vm.isDetail=$event}}},[_c('lecturer-detail',{attrs:{"id":_vm.teaId,"type":_vm.auditType},on:{"closeDialog":_vm.getCloseDialog}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }